/* 
  We've already included normalize.css. 

  But we'd like a modern looking boilerplate. 
  Clean type, sans-serif, and a nice color palette. 
  
*/

body {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: sans-serif;
  font-weight: 600;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

#gameboard {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
}

#gameboard canvas {
    flex: 0;
}

#chat {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 300px;
    background: #fafafa;
    border-left: 3px solid #eaeaea;
    font-family: monospace;
    font-size: 12px;
}
#chat-output {
    overflow: scroll;
    max-height: calc(100vh - 1.1rem);
}
#chat-input {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border: none;
}
